// App.js
import React from "react";
import Header from "../../components/Header";
import {
  Button,
  Card,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Logo from "../../assets/Group 47.svg";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  function navigateToHome() {
    window.location.href = "/";
  }

  return (
    <>
      <Helmet>
        <title>Privacy & Policy | npayindia</title>
      </Helmet>
      <Grid container>
        <Stack sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
          <img
            src={Logo}
            alt="Logo"
            style={{ marginLeft: 10 }}
            onClick={navigateToHome}
          />
        </Stack>
        <Grid
          sx={{
            height: "20vh",
            width: "100%",
            bgcolor: "#F4E4E5",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: 18, sm: 40, md: 50 },
              pt: { xs: 2, md: 3 },
              color: "#E84B4F",
            }}
          >
            Privacy Policy
          </Typography>
          <Typography>
            <Button
              onClick={navigateToHome}
              variant="contained"
              sx={{ bgcolor: "#E84B4F", m: { xs: 1, md: 1 } }}
            >
              Back To Home
            </Button>
          </Typography>
        </Grid>
        <Card
          sx={{
            p: { xs: 2, md: 2 },
            boxShadow: "20px",
            m: { xs: 2, md: 10 },
            bgcolor: "#ef9a9a",
            borderRadius: 5,
          }}
        >
          <Grid>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              Privacy Policy
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              (“NPay India”, “we”, “us”, “our”), respects every individual’s
              right to privacy and it is our endeavor to protect their personal
              and Sensitive personal data or information (defined below).
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              This Privacy Policy (“Policy”) applies to all visitors (i.e. those
              who do not have an Account with NPay India) and users (i.e.
              those who have an Account with NPay India) (collectively
              referred to as “User”) who share their personal and Sensitive
              personal data with us, while visiting, accessing, browsing and
              using our website and mobile application (collectively referred to
              as “Website”). The purpose of this Policy is to inform Users
              regarding collection, use, storage, transfer and disclosure of
              their data. Before submitting any information to us, please
              carefully read this Policy to understand how we shall treat your
              information.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              The User acknowledges that this Policy is a part of NPay India's
              Terms & Conditions and all the terms defined there have the same
              meaning here in this Policy. Access, browsing and continuous use
              of the Website constitutes the User’s unconditional consent to
              this Policy read together with the Terms of Use. If the User does
              not agree with the terms of this Policy, please do not visit and
              use our Website. This Policy can be easily accessed at various
              places on our Website, including but not limited to when Users
              create an account with us on our Website.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              NPay India reserves its right to modify, alter, and update this
              Policy at any time, with or without prior notice. Although, Users
              shall be notified of material changes through their registered
              email address or any other mode of communication available with
              us, it is advised that they consult this Policy regularly to be
              updated about the changes. If a User continues to use or avail the
              services of the Website after any modification, amendment,
              alteration or change of this Policy, irrespective of whether
              notice was sent to the User or published on the Website, such User
              is hereby deemed to provide consent to this Policy and N Pay
              India's other Policies and practices existing at the time of
              visiting, accessing and/or using the Website.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              This Policy is published in compliance of:
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              Section 43A of the Information Technology Act, 2000 (“Act”)
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              Regulation 4 of the Information Technology (Reasonable Security
              Practices and Procedures and Sensitive Personal Information)
              Rules, 2011 (“SPI Rules”)
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              DEFINITIONS
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              “Personal Information” as any information that relates to a
              natural person, which, either directly or indirectly, in
              combination with other information available or likely to be
              available with a body corporate, is capable of identifying such
              person. Such information includes name, address, mobile number,
              etc.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              “Sensitive personal data or information” of a person as personal
              information which consists of information relating to:
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - passwords;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - financial information such as bank accounts or credit card or
              debit card or other payment instrument details;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - physical, physiological and mental health condition;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - sexual orientation;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - medical records and history;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - biometric information;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - any of the information received under above clauses by body for
              processing or storage under lawful contract or otherwise.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              Information which is freely available in the public domain or
              furnished under the Right to Information Act, 2005 or any other
              law shall not be regarded as sensitive personal data or
              information.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              “Payment Data” is defined as end-to-end transaction details and
              information pertaining to payment or settlement transaction that
              is gathered / transmitted / processed as part of a payment message
              / instruction. Payment Data includes
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Customer data (Name, Mobile Number, email, Aadhaar Number, PAN
              number, etc. as applicable);
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Payment sensitive data (customer and beneficiary account
              details);
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Payment Credentials (OTP, PIN, Passwords, etc.); and,
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Transaction data (originating & destination system information,
              transaction reference, timestamp, amount, etc.).
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              Users provide their consent to the use, disclosure, storage,
              possession, receiving, dealing or handling of their Personal
              Information by accessing, browsing, or availing services on the
              Website.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              Users provide their specific consent to the use, disclosure,
              storage, possession, receiving, dealing or handling of their
              Sensitive personal data or information for lawful purposes
              enumerated in this policy through checking the box affirming such
              consent appearing at the time of creation of their Account on the
              Website.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              NPay India presumes adequate and lawful parental consent in case
              the Personal Information or Sensitive personal data or information
              is shared by a User under the age of 18 years.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              TYPES OF INFORMATION WE COLLECT
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              The Users who access and use our Website are required to submit
              certain Personal Information and Sensitive personal data or
              information for creating an Account, entering into transactions
              with us and obtaining customer support services. Personal
              Information and Sensitive personal data or information which may
              be collected by us includes:
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Full name and Age;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Username and Password;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - PAN;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Password of User’s Account registered with us;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Postal address;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - E-mail address;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Mobile number;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Internet Protocol (IP) Addresses (through cookies);
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - User’s feedback, queries, e-mails, letters, suggestions provided
              to us;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Information about the mobile/tab device the App is installed on
              or mobile device identifier.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - any other information relevant for accessing and using our
              Website as maybe required by us.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              PURPOSE OF COLLECTION
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              We shall collect your Personal Information or Sensitive personal
              data or information only for specific, clear and lawful purposes.
              These include: etc.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to give the User a safe, efficient, smooth and personalized
              experience while using our platform;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to give the User a safe, efficient, smooth and personalized
              experience while using our platform;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to respond to your queries or complaints generally related to
              our services, etc.;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to send promotional emails containing information about our
              existing and new products/services, newsletters, notify changes in
              this Policy or other policies etc.;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to analyze the traffic on our Website and conduct market
              research regarding users’ demographics, interests, and behavior;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to create new products and services;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to provide customer support services;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to detect, prevent and address error, fraud, criminal activity,
              abuse of our services and technical issues;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to enforce Terms of Conditions;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - to provide co-branded services in affiliation with more than one
              corporate entity;
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - other such lawful purposes as described at the time of
              collection of Personal Information or Sensitive personal data or
              information.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              HOW INFORMATION IS COLLECTED
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              We collect information which the User voluntarily provides us at
              the time of visiting, accessing, browsing and using our Website,
              our social media profiles, and customer support service. We also
              collect data automatically by analyzing your online behavior
              (through cookies).
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              The User has an option to not provide us with certain information
              or withdraw consent to provide certain information at any time, in
              accordance with the terms of this Policy. In case consent is
              withdrawn, we shall retain your Personal Information or Sensitive
              personal data or information for a period deemed appropriate by us
              or as mandated by law. On revoking of your consent, we reserve the
              right to limit access to the various features and services
              provided on the Website.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              DISCLOSURE OF INFORMATION
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              You expressly consent to and authorize us to share your Personal
              Information and Sensitive personal data or information with our
              trusted affiliates and any other third party, to the extent and
              for the lawful purposes as stated in this Policy. We shall not
              disclose any Personal Information or Sensitive personal data or
              information to any government institution or authority, except
              where such disclosure is
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - obligated by law,
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - requested by a lawfully authorized government authority,
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - pursuant to a judicial decree,
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - for enforcing and protecting the legal rights of NPay India or
              others’,
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - for seeking any relief,
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - for defending any charge,
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - for opposing any claim,
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - for enforcing this Policy or our Terms of Use,
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - for obtaining any legal advice.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - We also reserve our right to disclose, share or transfer your
              Personal Information or Sensitive personal data or information to
              a third party due to any change in ownership, merger,
              restructuring or sale of our business assets.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              USE OF COOKIES AND OTHER TECHNOLOGIES
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              To improve the effectiveness and usability of the Website for our
              Users, we use “cookies”, or similar technology to collect
              information and assign each visitor a unique random number called
              as a User Identification (User ID) in order to understand the
              User's individual interests. Our web servers automatically collect
              limited information about the User’s computer connection to the
              Internet, including User’s IP address, when the User visits the
              Website (User’s IP address is a number that lets computers
              connected to the internet know where to send data to the User such
              as the web pages viewed by the User).
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              Users may encounter “cookies” or other similar devices on certain
              pages of the Website that are placed by third parties. We do not
              control the use of cookies by third parties and disclaim any
              obligation or liability associated thereto.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              CORRECTION AND UPDATION OF INFORMATION
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              The User shall promptly notify us in case any Personal Information
              or Sensitive personal data or information supplied by such User as
              maintained in our records is inaccurate, misleading or incomplete.
              Further, the User may also review, update or modify the
              information by logging into their Account on the Website. We shall
              not be responsible for the authenticity of Personal Information or
              Sensitive personal data or information supplied by a User.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              RETENTION OF INFORMATION
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              Your Personal Information and Sensitive personal data or
              information may be retained and used until: the relevant purposes
              for the use of your information described in this Policy are no
              longer applicable; we are no longer required by applicable law,
              regulations, contractual obligations or legitimate business
              purposes to retain your information; the retention of your
              information is not required for the establishment, exercise or
              defense of any legal claim.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              MAILERS
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              NPay India may send direct mailers to the User at the email
              address given by the User. The User has the option to opt-out of
              this direct mailer by way of links provided at the bottom of each
              mailer or any other means as NPay India deems appropriate. N
              Pay India respects every User’s privacy and in the event that User
              chooses to not receive such mailers, NPay India will take all
              steps to remove the User from the list. If a User wants to remove
              his/her/it’s contact information from all mailing lists and
              newsletters, the same can be done through “Profile” page of
              his/her/it’s account with NPay India.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              DATA SECURITY AND STORAGE
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              Data Security Measures is committed to maintaining the security
              and confidentiality of user data. The following measures are
              implemented to safeguard your information:
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Encryption: All data transmitted between users and our servers
              is encrypted using industry-standard protocols.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Access Controls: Access to user data is restricted to authorized
              personnel only, and strict access controls are in place.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Firewalls and Intrusion Detection: Robust firewalls and
              intrusion detection systems are deployed to prevent unauthorized
              access.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Regular Security Audits: Periodic security audits are conducted
              to identify and address potential vulnerabilities.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              Data Storage
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Location of Data: User data may be stored on servers located
              within or outside your country of residence. By using our
              services, you consent to the storage of your data.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Retention Period: retains user data for as long as necessary to
              fulfill the purposes outlined in the Privacy Policy or as required
              by applicable laws.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Data Backup: Regular data backups are performed to prevent data
              loss and facilitate disaster recovery.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              User Responsibilities
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              While takes stringent measures to ensure data security, users are
              also responsible for:
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Secure Credentials: Keeping login credentials, including
              passwords and API keys, secure and confidential.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Device Security: Ensuring the security of the devices used to
              access 's services.
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1, ml: 1 } }}>
              - Unauthorized Access: Reporting any unauthorized access or
              security incidents promptly to.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              Data Breach Notification
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              In the event of a data breach that compromises the security of
              user data, will notify affected users in accordance with
              applicable data protection laws.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              Limitation of Liability
            </Typography>
            <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
              While endeavors to protect user data, users acknowledge that no
              method of transmission or storage is completely secure. Users
              agree that shall not be held liable for any unauthorized access,
              disclosure, alteration, or loss of data.
            </Typography>
            <Typography
              sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
            >
              <Button
                onClick={navigateToHome}
                variant="contained"
                sx={{ bgcolor: "#E84B4F", m: 1 }}
              >
                Back To Home
              </Button>
            </Typography>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default PrivacyPolicy;
