import {
  Typography,
  Paper,
  Grid,
  Button,
  Container,
  useTheme,
  useMediaQuery,
  Stack,
  Card,
  TextField,
  Drawer,
  List,
  ListItem,
  Slide,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SideBaar from "../assets/icons/SideBaar.png";
import ICON9 from "../assets/Icon (9).svg";
import ICON10 from "../assets/Icon (10).svg";
import ICON11 from "../assets/Icon (11).svg";
import ICON12 from "../assets/Icon (12).svg";
import ICON13 from "../assets/Icon (13).svg";
import ICON14 from "../assets/Icon (14).svg";
import ICON15 from "../assets/Icon (15).svg";
import ICON16 from "../assets/Icon (16).svg";
import Logo from "../assets/Group 47.svg";
import ICON17 from "../assets/cinematic_blur (1).svg";
import ICON18 from "../assets/group (4).svg";
import ICON19 from "../assets/group (5).svg";
import ICON20 from "../assets/businessmen-closing-deal-with-handshake 1.svg";
import Group1 from "../assets/man-cunting-stack-coins 1.svg";
import ICON21 from "../assets/male-employee-authenticates-customers-deliver-parcels-office 1.svg";
import ICON22 from "../assets/happy-indian-business-man-using-tablet-cafe 1.svg";
import ICON23 from "../assets/aerial-view-hands-working-computer-white-table-office 1.svg";
import ICON24 from "../assets/Social Icons.svg";
import ICON25 from "../assets/Social Icons-1.svg";
import ICON26 from "../assets/Social Icons-2.svg";
import ICON27 from "../assets/Social Icons-3.svg";
import BACKIMAGE from "../assets/Group2233.svg";
import { Link } from "react-router-dom";

function Home() {
  const [email, setEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile: any = useMediaQuery(theme.breakpoints.down("sm"));
  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleLogin = () => {
    window.location.href = "https://app.npayindia.com/auth/login";
  };

  const handleSign = () => {
    window.location.href = "https://app.npayindia.com/register";
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = () => {
    setOpen(false);
  };
  const cardData = [
    {
      id: 1,
      icon: ICON9,
      title: "Mobile Recharges and ",
      title2: "More",
      description: "Recharge your mobile phones,",
      description1: "DTH, and more from our user-friendly",
      description2: " platform.",
    },
    {
      id: 2,
      icon: ICON10,
      title: "DMT (Domestic ",
      title2: "Money Transfer)",
      description: "Seamlessly send and receive money",
      description1: "DTH, and more from our user-friendly",
      description2: " swift transfer of funds.",
    },
    {
      id: 3,
      icon: ICON11,
      title: "Aadhar-enabled",
      title2: " Services",
      description: "Utilize Aadhar-enabled systems",
      description1: "for cash withdrawals.",
    },
    {
      id: 4,
      icon: ICON12,
      title: "PAN Card Creation",
      title2: " and Correction",
      description: "Facilitate hassle-free PAN card ",
      description1: "creation and rectification services.",
    },
    {
      id: 5,
      icon: ICON13,
      title: "Loan Lead",
      title2: "Generation",
      description: "Access opportunities in loan lead  ",
      description1: "generation, making financial assistance more",
      description2: "accessible.",
    },
    {
      id: 6,
      icon: ICON14,
      title: "Insurance Premium ",
      title2: "Payments",
      description: "Effortlessly pay insurance premiums ",
      description1: "and access general insurance services.",
    },
    {
      id: 7,
      icon: ICON15,
      title: "Loan EMI",
      title2: "Collection",
      description: "Streamlined collection of loan  ",
      description1: "EMIs for a hassle-free financial",
      description2: "experience..",
    },
    {
      id: 8,
      icon: ICON16,
      title: "All Types of Bill ",
      title2: "Payments",
      description: "Facilitate various bill payments through ",
      description1: "our extensive distribution network.",
    },
  ];

  return (
    <>
      <Stack
        sx={{
          backgroundColor: "#F4E4E5",
          boxShadow: "none",
          padding: theme.spacing(2),
        }}
      >
        <Stack
          sx={{
            flexDirection: isMobile ? "row" : "row",
            alignItems: isMobile ? "center" : "flex-start",
            textAlign: isMobile ? "center" : "left",
            justifyContent: "space-between",
          }}
        >
          <img src={Logo} alt="Logo" style={{ marginLeft: 10 }} />
          <Stack
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? 3 : 20,
              marginLeft: isMobile ? 0 : 10,
            }}
          >
            {/* Mobile Navigation */}
            {isMobile ? (
              <>
                <Button sx={{ color: "#5E3377" }} onClick={handleDrawerOpen}>
                  <img
                    src={SideBaar}
                    alt="SideBaar"
                    style={{ width: "40px", height: "40px" }}
                  />
                </Button>
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={handleDrawerClose}
                  transitionDuration={500}
                  sx={{
                    "& .MuiDrawer-paper": {
                      width: "70vw",
                    },
                  }}
                >
                  <List>
                    <ListItem>
                      <Typography variant="h6">Menu</Typography>
                    </ListItem>
                    <ListItem onClick={handleMenuItemClick}>
                      <a
                        href="#home"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Typography variant="body1">Home</Typography>
                      </a>
                    </ListItem>
                    <ListItem onClick={handleMenuItemClick}>
                      <a
                        href="#services"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Typography variant="body1">Services</Typography>
                      </a>
                    </ListItem>
                    <ListItem onClick={handleMenuItemClick}>
                      <a
                        href="#Join Our Network"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Typography variant="body1">
                          Join Our Network
                        </Typography>
                      </a>
                    </ListItem>
                    <ListItem onClick={handleMenuItemClick}>
                      <a
                        href="#Become a Distributor"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Typography variant="body1">
                          {" "}
                          Become a Distributor
                        </Typography>
                      </a>
                    </ListItem>
                    <ListItem onClick={handleMenuItemClick}>
                      <a
                        href="#Contact Us"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Typography variant="body1"> Contact Us</Typography>
                      </a>
                    </ListItem>

                    <ListItem onClick={handleMenuItemClick}>
                      <Button sx={{ color: "#E84B4F" }} onClick={handleLogin}>
                        Login
                      </Button>
                    </ListItem>
                    <ListItem onClick={handleMenuItemClick}>
                      <Button
                        sx={{ backgroundColor: "#E84B4F", color: "#fff" }}
                        onClick={handleSign}
                      >
                        Sign up
                      </Button>
                    </ListItem>
                  </List>
                </Drawer>
              </>
            ) : (
              // Desktop Navigation
              <>
                <ul
                  style={{
                    display: "flex",
                    gap: 45,
                    listStyle: "none",
                    padding: 0,
                  }}
                >
                  <li>
                    <a
                      href="#home"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#services"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Services
                    </a>
                  </li>
                  <li
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="#Join Our Network"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Join Our Network
                    </a>
                  </li>
                  <li
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="#Become a Distributor"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Become a Distributor
                    </a>
                  </li>
                  <li
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="#Contact Us"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
                <Stack sx={{ flexDirection: "row", gap: 13 }}>
                  <Button sx={{ color: "#E84B4F" }} onClick={handleLogin}>
                    Login
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#E84B4F",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#F05757",
                      },
                    }}
                    onClick={handleSign}
                  >
                    Sign up
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      {/* {home} */}
      <Stack
        style={{
          backgroundImage: `url(${BACKIMAGE})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height:'60vh'
        }}
        id="home"
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            marginTop:'20px',
            "@media screen and (min-width: 768px)": {
              flexDirection: "row",
              alignItems: "flex-start",
              padding: "40px",
            },
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: 600,
                font: "Inter",
                color: "#fff",
              }}
            >
              Welcome to
            </Typography>
            <Typography
              sx={{
                fontSize: "50px",
                fontWeight: 600,
                font: "Inter",
                color: "#E84B4F",
              }}
            >
              N₹upay
            </Typography>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 700,
                font: "Inter",
                lineHeight: "30px",
                color: "#fff",
              }}
            >
              Empowering Financial Solutions
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                font: "Inter",
                lineHeight: "24px",
                color: "#fff",
                marginTop: 2,
              }}
            >
              N₹upay Private Limited is your gateway to empowered financial
              solutions. As a{" "}
              <Typography>
                Fintech company, we are committed to uplifting the
                underprivileged segments of the
              </Typography>{" "}
              <Typography>
                Indian economy, with a special focus on empowering the migrant
                population.
              </Typography>
            </Typography>
            <Button
              variant="contained"
              onClick={handleSign}
              style={{
                marginTop: "10px",
                backgroundColor: "#E84B4F",
                width: "100px",
              }}
            >
              Register
            </Button>
          </Grid>
        </Container>
      </Stack>
      <Stack sx={{ bgcolor: "#F4E4E5" }} id="Join Our Network">
        <Stack sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: { xs: "24px", md: "36px" },
              fontWeight: 600,
              font: "Inter",
              marginLeft: 1,
              color: "#E84B4F",
            }}
          >
            Join Our Network
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 200,
              font: "Inter",
              marginLeft: 2,
            }}
          >
            Be part of the financial revolution with N₹upay inclusive network.
            We welcome:
          </Typography>
        </Stack>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction={{ xs: "column", md: "row" }}
          spacing={3}
          sx={{ marginTop: 2, marginBottom: 5 }}
        >
          <Grid item xs={12} md={4}>
            <Stack
              sx={{
                display: "grid",
                gap: 3,
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  font: "Inter",
                  color: "#4D4D4D",
                  marginLeft: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={ICON17}
                  alt="image"
                  style={{
                    marginRight: "8px",
                    maxWidth: "24px",
                    maxHeight: "24px",
                  }}
                />
                Merchants
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  font: "Inter",
                  color: "#4D4D4D",
                  marginLeft: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={ICON18}
                  alt="group2"
                  style={{
                    marginRight: "8px",
                    maxWidth: "24px",
                    maxHeight: "24px",
                  }}
                />
                Distributors
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  font: "Inter",
                  color: "#4D4D4D",
                  marginLeft: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={ICON19}
                  alt="group3"
                  style={{
                    marginRight: "8px",
                    maxWidth: "24px",
                    maxHeight: "24px",
                  }}
                />
                Master Distributors
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 200,
                  font: "Inter",
                  marginLeft: 2,
                  marginTop: 3,
                }}
              >
                Ready to drive financial inclusion? Become{" "}
                <Typography>a part of N₹upay network and make a </Typography>
                <Typography>significant difference.</Typography>
              </Typography>
              <Button
                variant="contained"
                onClick={handleSign}
                style={{
                  marginTop: "10px",
                  backgroundColor: "#E84B4F",
                  width: "100px",
                }}
              >
                Register
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={ICON20} alt="Group" style={{ width: "300px" }} />
          </Grid>
        </Grid>
      </Stack>
      {/* Distributor Section */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{ padding: "20px" }}
        id="Become a Distributor"
      >
        <Grid item xs={12} md={4}>
          <img src={ICON21} alt="Groupa" style={{ width: "300px" }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            fontWeight={600}
            color="#E84B4F"
            marginBottom={2}
          >
            Become a Distributor
          </Typography>
          <Typography
            variant="body1"
            fontWeight={200}
            color="#717171"
            marginBottom={2}
          >
            Empower your entrepreneurial journey by collaborating with N₹upay as
            a distributor. As a distributor, you play a pivotal role in
            extending our financial services reach. Enjoy the benefits of
            advanced technology, robust support, and attractive commission
            structures.
          </Typography>
          <Typography
            variant="h6"
            fontWeight={600}
            color="#E84B4F"
            marginBottom={2}
          >
            Why Choose Distributorship with N₹upay?
          </Typography>
          <Typography
            variant="body1"
            fontWeight={200}
            color="#717171"
            marginBottom={2}
          >
            Comprehensive Service Portfolio: Access a wide range of financial
            services. Cutting-edge Technology: Utilize advanced infrastructure
            for seamless and secure transactions. Lucrative Commissions:
            Rewarding structures acknowledging efforts in financial inclusion.
            Dedicated Support: Get assistance for success and query resolutions.
          </Typography>
          <Button
            variant="contained"
            onClick={handleSign}
            style={{
              marginTop: "10px",
              backgroundColor: "#E84B4F",
            }}
          >
            Register
          </Button>
        </Grid>
      </Grid>
      {/* Master Distributor Section */}
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        spacing={3}
        style={{ padding: "20px" }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant="subtitle1"
            fontWeight={700}
            color="#E84B4F"
            marginBottom={1}
          >
            Elevate Your Role
          </Typography>
          <Typography
            variant="h4"
            fontWeight={600}
            color="#E84B4F"
            marginBottom={1}
          >
            Master Distributorship
          </Typography>
          <Typography variant="h4" fontWeight={600} marginBottom={1}>
            Opportunities
          </Typography>
          <Typography
            variant="body1"
            fontWeight={200}
            color="#717171"
            marginBottom={1}
          >
            Master Distributors are integral to our network, overseeing
            territories and distributors. This role offers added
            responsibilities and exclusive benefits.
          </Typography>
          <Typography
            variant="h6"
            fontWeight={600}
            color="#E84B4F"
            marginBottom={1}
          >
            Benefits of Master Distributorship
          </Typography>
          <Typography
            variant="body1"
            fontWeight={200}
            color="#717171"
            marginBottom={1}
          >
            Territorial Leadership: Lead financial service expansion and drive
            growth in designated regions. Exclusive Territory Rights: Maximize
            potential earnings with exclusive territorial rights. Enhanced
            Commissions: Enjoy superior commission structures for pivotal roles
            in our network. Training and Development: Access specialized
            training to enhance leadership and management skills.
          </Typography>
          <Button
            variant="contained"
            onClick={handleSign}
            style={{
              marginTop: "10px",
              backgroundColor: "#E84B4F",
              width: "100px",
            }}
          >
            Register
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <img src={ICON22} alt="Groupb" style={{ width: "300px" }} />
        </Grid>
        {/* services  */}
        <Stack sx={{ alignItems: "center" }} id="services">
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: 600,
              font: "Inter",
              color: "#E84B4F",
              marginLeft: 1,
            }}
          >
            Our Array of Services
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 200,
              font: "Inter",
              color: "#717171",
              marginLeft: 2,
            }}
          >
            At N₹upay, we offer an extensive range of services to simplify
            financial transactions, ensuring accessibility, security, and
            convenience:
          </Typography>
        </Stack>
        <Container style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>
          <Carousel
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 600,
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 600,
                  min: 0,
                },
                items: 1,
              },
            }}
            swipeable
            draggable
            showDots={false}
            autoPlay
            infinite
            autoPlaySpeed={2000}
          >
            {cardData.map((card, index) => (
              <Card
                key={card.id}
                sx={{
                  width: "400px",
                  height: "200px",
                  mb: "10px",
                  mt: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: "8px",
                  bgcolor: "#FFFFFF",
                }}
              >
                <img
                  src={card.icon}
                  style={{ width: 48, height: 48 }}
                  alt="Icon"
                />
                <Typography variant="h6" sx={{ fontWeight: 700, mt: 2 }}>
                  {card.title}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700, mb: 1 }}>
                  {card.title2}
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "#4D4D4D" }}>
                  {card.description}
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "#4D4D4D" }}>
                  {card.description1}
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "#4D4D4D" }}>
                  {card.description2}
                </Typography>
              </Card>
            ))}
          </Carousel>
        </Container>
        {/* Contact Us */}
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: ["column", "column", "row"],
            alignItems: "center",
            gap: [1, 1, 15],
            padding: ["20px", "20px", "40px"],
            backgroundColor: "#F4E4E5",
            marginTop: 5,
            width: "100%",
          }}
        >
          <Grid
            sx={{
              display: "grid",
              marginLeft: ["0%", "0%", "70px"],
              marginTop: [3, 3, 0],
            }}
            id="Contact Us"
          >
            <Card
              sx={{
                display: "grid",
                width: ["300px", "100%", "400px"],
                height: ["auto", "auto", "500px"],
                borderRadius: "20px",
                padding: ["24px", "24px", "24px 32px 24px 24px"],
                marginTop: [2, 2, 2],
              }}
            >
              <Typography
                sx={{
                  fontSize: ["18px", "20px", "20px"],
                  fontWeight: 600,
                  font: "Inter",
                  color: "#E84B4F",
                  textAlign: "center",
                }}
              >
                Contact Us{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: ["12px", "14px", "14px"],
                  fontWeight: 200,
                  font: "Inter",
                  color: "#717171",
                }}
              >
                For inquiries or assistance, connect with our dedicated{" "}
                <Typography>support team.</Typography>
              </Typography>
              <TextField fullWidth type="text" label="Full Name" size="small" />
              <TextField fullWidth type="text" label="Phone" size="small" />
              <TextField fullWidth type="text" label="E-mail" size="small" />
              <TextField
                fullWidth
                type="text"
                label="Shop Address"
                size="small"
              />
              <TextField
                fullWidth
                type="text"
                label="Message"
                multiline
                rows={4}
              />
              <Button
                variant="contained"
                fullWidth
                size="small"
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#E84B4F",
                }}
              >
                Register
              </Button>
            </Card>
          </Grid>
          <img
            src={ICON23}
            alt="Frame"
            style={{ width: "350px", height: "auto" }}
          />
        </Grid>
      </Grid>
      <Stack sx={{ bgcolor: "#263238" }}>
        <Grid
          sx={{
            marginTop: 3,
            display: "flex",
            justifyContent: "space-between",
            gap: 20,
            marginLeft: 9,
            padding: "24px 32px 24px 32px",
            "@media screen and (max-width: 1024px)": {
              flexDirection: "column",
              marginLeft: 0,
            },
          }}
        >
          <Grid
            sx={{
              color: "#F5F7FA",
              fontSize: "14px",
              fontFamily: "Inter-Regular",
            }}
          >
            <Typography>Copyright © 2020 N₹upay</Typography>
            <Typography>All rights reserved</Typography>
            <Typography sx={{ marginTop: 5 }}>
              <img src={ICON24} alt="Icon" style={{ margin: "0 10px 0 0" }} />
              <img src={ICON25} alt="Icon1" style={{ margin: "0 10px 0 0" }} />
              <img src={ICON26} alt="Icon2" style={{ margin: "0 10px 0 0" }} />
              <img src={ICON27} alt="Icon3" />
            </Typography>
          </Grid>
          <Grid sx={{ color: "#F5F7FA", fontFamily: "Inter-Regular" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
              Company
            </Typography>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "100px", lineHeight: 2 }}
            >
              Services
              <Typography
                sx={{ fontSize: "12px", fontWeight: "100px", lineHeight: 2 }}
              >
                Join Our Network
              </Typography>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "100px", lineHeight: 2 }}
              >
                Become a Distributor
              </Typography>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "100px", lineHeight: 2 }}
              >
                Contact us
              </Typography>
            </Typography>
          </Grid>
          
          <Grid sx={{ color: "#F5F7FA", fontFamily: "Inter-Regular" ,display:'grid' }}>
                <Link to="/terms" style={{ textDecoration: 'none', color: 'inherit' ,fontSize: "12px", fontWeight: "400px", lineHeight: 2}}>
                  Terms&conditions
                </Link>
            <Link to="/PrivacyPolicy" style={{ textDecoration: 'none', color: 'inherit',fontSize: "12px", fontWeight: "400px", lineHeight: 2 }}>
                  Privacy Policy
                </Link>
          </Grid>
          <Grid sx={{ color: "#F5F7FA", fontFamily: "Inter-Regular" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
              Useful Links
            </Typography>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "100px", lineHeight: 2 }}
            >
              Login
              <Typography
                sx={{ fontSize: "12px", fontWeight: "100px", lineHeight: 2 }}
              >
                Sign up
              </Typography>
            </Typography>
          </Grid>
          <Grid sx={{ color: "#F5F7FA", fontFamily: "Inter-Regular" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
              Stay up to date
            </Typography>
            <TextField
              type="text"
              placeholder="Your email address"
              size="small"
              value={email}
              onChange={handleEmailChange}
              style={{
                color: "#F5F7FA",
                borderRadius: "10px",
                backgroundColor: "#D9DBE1",
                marginTop: 1,
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
export default Home;
