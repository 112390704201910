    // App.js
import React from "react";
import Header from "../../components/Header";
import {
  Button,
  Card,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Logo from "../../assets/Group 47.svg";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Terms = () => {
  function navigateToHome() {
    window.location.href = "/";
  }

  return (
    <>
     <Helmet>
        <title>Terms And Conditions | npayindia</title>
      </Helmet>
      <Grid container>
        <Stack sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
          <img
            src={Logo}
            alt="Logo"
            style={{ marginLeft: 10 }}
            onClick={navigateToHome}
          />
        </Stack>
        <Grid
          sx={{
            height: "20vh",
            width: "100%",
            bgcolor: "#F4E4E5",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: 18, sm: 40, md: 50 },
              pt: { xs: 2, md: 3 },
              color: "#E84B4F",
            }}
          >
            Terms and Conditions
          </Typography>
          <Typography>
          <Button
            onClick={navigateToHome}
            variant="contained"
            sx={{ bgcolor: "#E84B4F", m:{xs:1,md:1}}}
          >
            Back To Home
          </Button>
        </Typography>
        </Grid>
        <Card sx={{p:{ xs:2, md:2 },boxShadow:'20px',m:{xs:2,md:10},bgcolor:"#ef9a9a",borderRadius:5}}>
        <Grid>
          <Typography
            sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
          >
            TERMS OF USE
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            These terms and conditions (“Terms of Use” or “Terms of Service ”or
            “Terms ”) are a computer-generated electronic record published under
            Rule 3 of the Information Technology (Intermediary Guidelines and
            Digital Media Ethics Code) Rules, 2021 (as amended from time to
            time) read with Information Technology Act, 2000 (as amended from
            time to time) and does not require any physical or digital
            signatures.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            These Terms are legally binding and govern your use of “NPay India
            Platform” created, owned and operated by , a limited company
            incorporated under the laws of India, having registered office at
            (“NPay India” or (“Company ”). Please read these Terms carefully
            before using the Platform and/or availing any of the Services
            provided by NPay India through its Platform.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            By accessing or using the Platform or Services, you agree to be
            bound by these Terms and NPay India’s Privacy Policy. If you do
            not agree to be bound by these Terms and the Privacy Policy, you
            shall not use the Platform and Services in any manner whatsoever.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            These Terms are in addition to and not in derogation of any other
            terms stipulated by NPay India from time to time. In case of any
            conflict between these Terms and such other terms, these Terms shall
            prevail. Throughout these Terms, “we” and “our” shall refer to N
            Pay India and “you” and “your” shall refer to you, i.e., the User
            (defined below) of the Platform. Both NPay India and the User are
            collectively referred to as “Parties” and individually referred to
            as “Party” hereof.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Notwithstanding anything contained elsewhere, NPay India reserves
            the right to modify, alter, amend, revise these Terms, update the
            Platform and Services at any time, without prior notice, to improve
            your experience on the Platform and on account of changes in
            Applicable Law. It is strongly recommended that you visit the
            Platform periodically to review the latest and updated version of
            the Terms. If you do not agree with any changes made by us, you may
            stop using the Platform and Services. Your continued access or use
            of the Platform or Services after such modifications/updation shall
            constitute your acceptance of the modifications/updation.
          </Typography>
          <Typography
            sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
          >
            ACCEPTANCE
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            These Terms were last updated on 12 November 2023. By accessing,
            signing-up and/or accepting or using the Platform or the Services in
            any manner whatsoever, you acknowledge that you have read these
            Terms and agree to be bound by them. You also acknowledge that you
            have read our Privacy Policy and agree to be bound by it. By
            accessing or using the Platform or Services, you represent that you
            have provided us with valid credentials which may be used to verify
            your identity. We reserve the right to restrict, suspend, or
            terminate your access to the Platform or Services, at any time
            without prior notice, if we believe that you are in breach of these
            Terms. For the security of your account and to enhance your overall
            experience on the Platform, we may use certain technologies to
            monitor your activities on the Platform. Npay reserves the right to
            make your details available to its affiliates and partner
            institutions and you may be contacted by them for information and
            sales enquiries through e-mail, telephone and/or SMS. You agree to
            receive promotional materials and/or special offers from Npay its
            affiliates and partner institutions. These promotional materials
            and/or special offers will be consent based and you will have the
            option to unsubscribe.
          </Typography>
          <Typography
            sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
          >
            ELIGIBILITY
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            You shall register or become a User of the Platform or Services only
            if you are 18 years or above, and can enter into legally binding
            contracts as per Applicable Law. To access/use the Platform or
            Services, you need a valid e-mail address and Indian mobile phone
            number. Users who have been previously suspended or removed from
            availing any of the Services shall not be eligible to access/use the
            Platform or Services at any time. Users shall not impersonate any
            person or entity, or falsely state or otherwise misrepresent
            identity, age or affiliation with any person or entity and you may
            not have more than one active Account (defined below).
          </Typography>
          <Typography
            sx={{ p: { xs: 1, md: 1 }, fontWeight: 600, fontSize: 18 }}
          >
            DEFINITIONS
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            For the purpose of these Terms including General Terms and
            Conditions, Digi Kendra Terms and Conditions and Prepaid Card Terms
            and Conditions, the following words and phrases shall have the
            meaning assigned to them here in below.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “Account”
            <Typography sx={{ color: "#F4E4E5" }}>
              shall have the same meaning as ascribed to it under Clause 1.2 of
              these Terms;
            </Typography>
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “Affiliate”
            <Typography sx={{ color: "#F4E4E5" }}>
              shall mean and include any individual, entity, firm, body
              corporate, which directly or indirectly, controls, is controlled
              by, or is under common control with NPay India;
            </Typography>
          </Typography>
          <Typography
            sx={{
              ml: 1,
              mt: 1,
              fontWeight: 200,
              fontSize: 15,
            }}
          >
            “Applicable Law”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            shall mean and include (i) any law, statute, rule, regulation,
            order, circular, decree, directive, judgment, decision or other
            similar mandate of any applicable central, national, state or local
            governmental/regulatory authority having competent jurisdiction and
            force of law over, or applicable to you, NPay India or the subject
            matter in question, as may be amended from time to time, and (ii)
            any notification, circular, directive or other similar instructions
            issued by a financial sector regulator including but not limited to
            the Reserve Bank of India (“RBI ”) and/or rules, regulations, roles,
            responsibilities and processes laid down by National Payments
            Corporation of India (“NPCI ”) and other law enforcement agencies or
            regulatory authorities from time to time;
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “Chargeback”
            <Typography sx={{ color: "#F4E4E5" }}>
              shall mean and include reversal of any transaction made by Service
              Provider ’s User on account of, including but not limited to:
            </Typography>
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            - any alleged forgery of the User ’s account, card or other details
            pertaining to the transaction;
            <Typography sx={{ color: "#F4E4E5" }}>
              - any charge/debit made on Account or card that has been listed as
              a hot listed card or otherwise
            </Typography>
            <Typography sx={{ color: "#F4E4E5" }}>
              listed on the card association warning bulletins; duplicate
              processing of the transaction; any amount required to be refunded
              due to denial of a transaction by the User/valid card holder as
              wrongly charged payment/extra payment and/or due to fraudulent
              use/misuse of personal and financial information of the Servi ce
              Provider ’s User by any unauthorized person(s) and/or; any other
              reason as required/approved by the concerned banks, as the case
              may be;
            </Typography>
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “Confidential Information”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            shall mean and include any and all business, technical and financial
            information of NPay India or of any of its Affiliates that is
            related to any of the arrangements contemplated in these Terms or
            any other document in which these Terms are incorporated by
            reference or otherwise disclosed by NPay India to the User; any
            information which relates to its financial and/or business
            operations, including but not limited to, specifications, models,
            merchant lists/information samples, reports, forecasts, current or
            historical data, computer programs ordocumentation and all other
            technical, financial or business data, information related to its
            internal management, customers, products, services, anticipated
            products/services, processes, financial condition, employees,
            merchants, Intellectual Property (defined below), marketing
            strategies, experimental work, trade secrets, business plans,
            business proposals, customer contract terms and conditions,
            compensation/commission/service charges payable to the User, and
            other valuable confidential information and materials that are
            customarily treated as confidential or proprietary, whether or not
            specifically identified as confidential or proprietary;
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “Effective Date” or “Registration Date”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            shall mean the date of registration of Account of the User on NPay
            India’s platform.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “NPay India Platform or Platform”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            shall mean and include without limitation, NPay India’s websites
            or web applications including NPay India.in, NPay India.pro with
            all its sub-domains and any other linked web pages, mobile
            applications (Android and iOS), mobile site services, API keys,
            features or content and other digital assets or properties
            developed, owned and operated by the Company, as the case may be, to
            facilitate the User to access and/or use the Services;
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “NPay India Policies” “NPay India Policies”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            shall mean and include these Terms, Privacy Policy, Grievance Policy
            and any other terms and conditions/policies available and updated
            from time to time on the Platform;
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “Intellectual Property”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            shall mean all intellectual property used for the purpose of or in
            association with or in relation to the performance of these Terms
            and applicable agreement, and includes without limitation, (a)
            software, operating manuals, software code, program, instructions,
            specifications, processes, input methods, data or information used
            in relation to, or in association with, or for the operation of the
            software installed by NPay India, (b) patents, copyrights,
            designs, trademarks, service marks, trade names, business names,
            logos, symbols, styles, color combinations used by NPay India
            during the course of its business and all depictions, derivations
            and representations, graphics, images, content, and the “look and
            feel ”of all of the above (whether registered or not), (d) all
            information, data or material in whatever form, whether tangible or
            not, provided by NPay India to the User on the Platform; and (e)
            all techniques, formulae, patterns, compilations, processes,
            inventions, practices, methodology, techniques, improvement, utility
            model, procedures, designs, skills, technical information, notes,
            experimental results, service techniques, samples, specifications of
            the products or Services, labelling specifications, rights on
            software, and any other knowledge or know-how of any nature
            whatsoever.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “KYC” or “Know Your Customer”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            shall mean the process prescribed under the Applicable Law to
            collect and authenticate a User ’s personal or business
            identification details before offering Services to the User;
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “Services”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            shall mean the services provided, either directly or indirectly, by
            NPay India and/or any Service Provider to the User including, but
            not limited to, access and use of the Platform, banking services,
            non-banking services and other such services;;
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “Service Provider”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            shall mean any banking and/or non-banking entity with which NPay
            India has, directly or indirectly, executed an agreement for
            facilitating transactions between such entity and its customers;
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 200,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            “Users”
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            shall mean and include individuals, body corporate, partnership,
            limited liability partnership, digi kendra, merchants, etc., who
            accessing the Platform and/or using the Services.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            GENERAL TERMS AND CONDITIONS
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            These General Terms and Conditions govern the User ’s access and use
            of the Services.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            INFORMATION SUPPLIED TO NPay India
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Users agree that the information provided by the Users to NPay
            India on registration and at all other times, including payment,
            shall be true, accurate, current, and complete. If you provide any
            information that is untrue, inaccurate, not updated or incomplete
            (or becomes untrue, inaccurate or incomplete), or NPay India has
            reasonable grounds to suspect that such information is untrue,
            inaccurate, not updated or incomplete, NPay India shall have the
            right to suspend or terminate your Account and/or refuse any and all
            current/future access/use of the Platform or Services or any portion
            thereof.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            SECURITY OF ACCOUNT
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Users ’mobile number, Permanent Account Number (“PAN”) and e-mail
            shall be used to identify their Account with NPay India through
            which Services shall be dispensed (“Account”). The User is
            recommended to choose a strong password and Personal Identification
            Number (“PIN”) to protect their Account. The User shall not share
            their password and PIN with anyone. Neither NPay India nor its
            Service Providers, under any circumstances, will ever ask for a
            User's Account password, PIN or OTP or any other Confidential
            Information. If a User has any reason to believe that his/her
            Account is no longer secure, the User shall immediately change the
            Account password and PIN. In case the User is unable to secure
            his/her Account or has sufficient reason to believe that his/her
            Account might be compromised, the User shall inform NPay India
            promptly.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            PRIVACY POLICY
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            All information collected from a User pursuant to provision of
            Services under these Terms is subject to NPay India’s Privacy
            Policy. By using our Platform and/or Services, you hereby consent to
            the use of your information as outlined in our Privacy Policy
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            RECLAIMING INACTIVE ACCOUNTS
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            NPay India reserves the right to reclaim an Account without any
            prior notice to the concerned User if the Account is found to be
            inactive. For the purpose of this clause, an Account is considered
            as inactive if the concerned User has not signed in and/or has not
            done any transaction(s) for more than three (3) consecutive months.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            THIRD PARTY SITES, PRODUCTS AND SERVICES
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            NPay India’s Services and/or Platform may include links or
            references to third-party websites or services solely for User ’s
            convenience (“Reference Sites”). NPay India has no control over
            the Reference Sites and does not endorse any such Reference Sites or
            the information, materials, products, or services contained on or
            accessible through them. Correspondence or business dealings between
            Users and the concerned Reference Sites are solely between such User
            and the concerned Reference Site. Access and use of Reference Sites,
            including the information, materials, products, and services on or
            available through Reference Sites is solely at the concerned User ’s
            own risk. You acknowledge and agree that NPay India shall not be
            liable, directly, or indirectly, for any loss or damage caused or
            alleged to be caused by your use of or reliance on any materials,
            products, and services on or available through Reference Sites. It
            is also clarified that NPay India shall have no liability with
            respect to any acts, omissions, errors, representations, warranties,
            breaches, or negligence of the Reference Sites or for any personal
            injuries, death, property damage, or other damages or expenses
            resulting from your interactions with the Reference Sites.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            COMMUNICATION POLICY
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The User hereby accepts that:
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            NPay India may send transactional or non-transactional business
            communication in the form of SMS or email or push notifications as
            part of its efforts to fulfil your request or service your Account.
            A User may continue to receive transactional and critical
            communication from NPay India even after opting out of marketing
            communications. You expressly waive any registration or preference
            made under DND/NCPR list under applicable TRAI regulations.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            NPay India will make the best efforts to provide notifications and
            it shall be deemed that the User shall have received the information
            sent from it during the course of, or in relation to, using the
            Platform or availing any Services. NPay India shall not be under
            any obligation to confirm the authenticity of the person(s)
            receiving the alert. The User shall not hold the Company liable for
            non-availability of any notification service in any manner
            whatsoever.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            LIMITATION OF LIABILITY
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            In no event will NPay India or its directors, agents, officers, or
            employees be liable to a User for any special, indirect, incidental,
            consequential, punitive, or exemplary damages (including without
            limitation lost business opportunities, lost revenues, or loss of
            anticipated profits or loss of goodwill, or any other pecuniary or
            non-pecuniary loss or damage of any nature whatsoever) of any kind
            arising out of or relating to:
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - these Terms or any other agreement entered between the User and
            NPay India;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - the Services, Platform, or Reference Sites;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - User ’s use or inability to use the Services, Platform, or any
            Reference Sites, even if NPay India or its authorized
            representative has advised of the possibility of such
            inaccessibility;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - unauthorized access to or alterations or transmission of data, any
            material or data sent or received or not sent or received;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - any transaction(s) entered into by any third person or conduct of
            any other third-party or any infringement of another ’s intellectual
            property rights;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - the use of counterfeit or stolen cards, or stolen devices, or;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - fraudulent electronic transactions.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            It shall be at the sole discretion of NPay India to reverse any
            transaction subject to approval of the concerned Service Provider.
            Notwithstanding the above, if any court of law finds that NPay
            India or its directors, officers, or employees, are liable to
            indemnify a concerned User despite the existence of this Clause 1.7,
            such liability shall not exceed the amount paid by the concerned
            User, if any, for using the portion of the Services or the Platform
            giving rise to such cause of action.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Users acknowledge and agree that NPay India has offered its
            products and Services, set its prices, and entered into these Terms
            and applicable agreement in reliance upon the warranty, disclaimers,
            and the limitations of liability set forth herein.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Users acknowledge that the warranty, disclaimers, and the
            limitations of liability set forth herein reflect a reasonable and
            fair allocation of risk between Users and NPay India, and that the
            warranty, disclaimers, and the limitations of liability set forth
            herein form an essential basis of the bargain between Users and
            NPay India. NPay India would not be able to provide the Services to
            Users on an economically reasonable basis without these limitations.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Users understand that NPay India or the Service Providers may
            reject authentication and/or authorization of transaction placed by
            Users for any reason including but not limited to insufficient
            funds, incorrect authentication details provided, expired card/bank
            account, risk management, suspicion of Fraudulent Transaction
            (defined below), selling of restricted or banned items, use of
            compromised cards or bank account numbers, use of banned/blacklisted
            cards or bank account numbers, use of suspicious API or in
            accordance with Applicable Law.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Users further acknowledges that as a security measure NPay India
            and/or the Service Providers may at their sole discretion, and in
            accordance with Applicable Law, permanently or temporarily, block
            any or all Services from any specific blocked or blacklisted group
            of IP addresses, devices, geographic locations and/or undertake any
            such risk mitigation measures as deemed necessary.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            As a risk management tool, NPay India and/or the Service Providers
            reserve the right to limit or restrict the transaction size, amount
            and/or monthly volume at any time. NPay India considers various
            factors and such determination will be at its sole discretion.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            FRAUDULENT TRANSACTIONS
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            If NPay India identifies any unauthorized transaction and/or use
            case (“Fraudulent Transaction ”), the User shall be notified by
            NPay India. The User shall furnish documents and information
            pertaining to the transaction within one (1) day (or such other
            period specified by the bank).
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            The User agrees and acknowledges that:
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            - if the User is unable to furnish relevant documents, or
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            - the bank is not satisfied with the documents furnished by the
            User, then the bank shall be entitled to order NPay India to
            affect a reversal of the amount associated with the Fraudulent
            Transaction.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            NPay India shall also be entitled to suspend the settlement of the
            amount under dispute or hold the value of transaction under dispute
            from following the settlement of the transaction during the pendency
            of inquiries, investigations, and resolution thereof by the Service
            Provider to the User.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            If the amount in respect of the Fraudulent Transaction has already
            been settled to you pursuant to these Terms, any dispute arising in
            relation to the said Fraudulent Transaction, following settlement,
            shall be resolved in accordance with notifications, circulars and
            guidelines issued by the RBI in this regard from time to time.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The User also agrees and acknowledges that it shall be liable to pay
            fines, penalties and charges imposed by banks, card payment networks
            or any regulatory authority on account of transactions that are in
            violation of Applicable Law.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The User further agrees and acknowledges that following NPay India
            incurring the charge stipulated in clause 1.8 (ii), if the available
            transaction amounts are insufficient for deduction of the Amount
            associated with the transaction, then NPay India is entitled to
            issue a debit note seeking reimbursement of the amount from the
            User. The User shall reimburse the amount within three (3) working
            days of receipt of the debit note.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The Parties hereto agree that the User shall be solely liable for
            any payment instructions for transactions accepted by the User as
            per the Terms hereof which are subsequently disputed due to (a)
            authorization by the User of any Transaction with an amount
            exceeding the User ’s account balance or credit limit, or (b)
            authorization by the User of a Fraudulent Transaction
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The User hereby acknowledges and agrees that NPay India is a
            facilitator for payment processing services which are requested by
            the User. NPay India shall not be responsible for any unauthorized
            transactions done by any person including a third-party which may
            cause infringement of such third-party ’s rights or loss of any
            amounts claimed by the customers. Users shall be responsible to
            ensure due protection while transacting online or otherwise. NPay
            India will assist the Users in settling any queries related to the
            Services that arise between the Users, and their customers. However,
            NPay India shall bear no responsibility with respect to any
            dispute or query related to payment made to the Users by their
            customers.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            You agree and acknowledge that NPay India shall not be liable for
            any liability arising in respect of a Fraudulent Transaction whether
            it is domestic or international.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            SECURITY OF YOUR ACCOUNT
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            While accessing/using the Platform and Services, Users shall comply
            with these Terms and Applicable Law including without limitation
            pertaining to data security and management, data storage, sharing
            and data protection, and shall ensure the same level of compliance
            by all its employees, agents, and representatives.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            User confirms and certifies that it shall not engage in any act that
            violates Applicable Law. It shall also ensure that financial data or
            any other information of the customers received by the User are
            stored in a system only in India. This data shall include full end
            to end transaction details/information collected/carried/processed
            as part of the message/payment instructions. The User also
            acknowledges and agrees that the Account balance maintained by the
            Users on the Platform shall be non-interest bearing and shall be
            forfeited by NPay India if found lying unused for more than three
            hundred and sixty-five (365) days.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            User hereby grants and confirms the existence of the right of lien
            and set-off with NPay India, which NPay India may exercise at
            any time without prejudice to any of its specific rights under any
            other agreements with the User. NPay India may at its sole
            discretion and without notice to User, utilize and/or appropriate
            any monies belonging to such User which are lying/deposited with N
            Pay India or due by NPay India to such User and/or any balance
            monies lying and/or any monies deposited and/or any future
            receivables by way of deposit of the monies in the Account/wallet in
            order to recover funds for transactions which are incorrectly or
            erroneously processed.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            NPay India shall also be entitled to freeze operations of the
            Account of any User and/or exercise the right of lien and/or set-off
            with or without notice, if NPay India is of the view and/or
            suspects any omission and/or commission of any act including but not
            limited to any malafides/ fraud/ mischief/ impersonation/ phishing/
            hacking/ unauthorized access etc., for such period as it may deem
            fit until it has received to its satisfaction necessary
            clarifications as sought from the User and/or until it is convinced
            that operations in that Account can recommence. The User shall
            forthwith furnish all clarifications/information sought by N Pay
            India and NPay India shall not be held responsible or liable for
            any losses, expenses, costs etc. suffered or incurred by the User by
            reason of freezing of the Account and/or due to exercise of the
            right of lien and set-off by NPay India. NPay India shall also
            be entitled to defreeze the Account or remit the amount standing to
            the credit of such Account(s) whether jointly or singly, as the case
            may be, to the concerned authority without any notice to the User
            pursuant to the receipt of any notice or direction to that effect
            from any statutory/regulatory/legal/investigative authorities.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            USER ’S REPRESENTATIONS AND WARRANTIES
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The User represents and warrants that:
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it is eligible and has the legal capacity or authority to enter
            into, exercise rights under, and perform obligations under these
            Terms.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it has read and reviewed these Terms and had sufficient time to
            evaluate and investigate the provision of Services under the
            applicable agreement and the financial requirements and risks
            associated with the same.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it has duly accepted these Terms, which form a legal, valid, and
            binding obligation, and are enforceable in accordance with the
            clauses stipulated herein.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            its use of the Services is solely for its own bona fide use and in
            compliance with Applicable Law as well as any directions issued by
            NPay India from time to time.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it shall immediately intimate NPay India of any violation or
            potential violation of these Terms or of other circumstances that
            may cause damage to NPay India’s goodwill and reputation.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it shall be responsible to obtain any and all consents and approvals
            that are required from regulatory or governmental authorities,
            including any consent from customers required to transfer personal
            data to the User and/or NPay India or its Affiliates required for
            the access/use of the Platform or Services, as the case may be, in
            accordance with Applicable Law.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it shall be solely responsible for and hereby undertakes to strictly
            comply with NPay India’s Privacy Policy, and Applicable Law
            including but not limited to data protection, security, piracy, and
            directions issued by the regulators relating to payment data, and
            shall obtain and maintain in full force and effect all registrations
            required under Applicable Law for the operation of the business in
            terms of the applicable agreement. It shall also not deal in any of
            the products or services as mentioned in list of Restricted Services
            of these Terms. Please note that this list of Restricted Services is
            subject to addition/change without prior intimation to you.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it shall not access (or attempt to access) the Platform and the
            Services by any means other than through the interface that is
            provided by NPay India. It shall not use any deep-link, page
            scrape, robot, spider or other automated device, program, algorithm,
            or methodology, or any similar or equivalent manual process, to
            access, acquire, copy, or monitor any portion of the Platform, or in
            any way reproduce or circumvent the navigational infrastructure or
            presentation of the Platform.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it shall not attempt to gain unauthorized access to any portion or
            feature of the Platform, or any other systems or networks connected
            to the Platform or to any server, computer, network, or to any of
            the services offered on or through the Platform, by hacking,
            password mining or any other illegal means.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it shall inform the Company in case income tax return is not filed
            by the User for the previous financial years starting from FY
            2020-2021 and undertake to indemnify and keep indemnified N Pay
            India against any and all losses, claims, liabilities, costs, taxes,
            interest, penalties imposed by any government and regulatory
            authority for non-complying with Section 206AB or any other
            provisions of Income Tax Act, 1961. The User also agrees that in
            case of any non-compliance as per the above-mentioned section,
            NPay India shall recover TDS amount from the User along with interest
            and penalty.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            it shall assist NPay India in furnishing to its auditors, Service
            Providers, governmental authorities, or law enforcement agencies,
            forthwith upon request from time to time, KYC Documents, relevant
            books, the original copy/copies of proof of transactions, invoices,
            or other records, and shall retain records relating to transactions
            for a period of 10 (ten) years from the relevant date of the order
            placed on the Platform.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            INDEMNIFICATION
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            User agrees to indemnify, save, and hold NPay India, its
            Affiliates, contractors, employees, officers, directors, agents and
            its third-party suppliers, licensors, and partners harmless from any
            and all claims, demands, actions, suits which may be threatened or
            brought against NPay India, and also against all losses, damages,
            liabilities, costs, charges, and expenses, including without
            limitation, legal fees and expenses arising out of or related to:
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - Users ’use or misuse of Platform/Services;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - User ’s use or misuse of the Account;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - any violation by the User of these Terms or applicable agreement;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - any breach of representations, warranties and covenants made by
            the User in these Terms or applicable agreement;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - any claim or legal notice or quasi-legal proceedings to which
            NPay India may be required to become party or to which NPay India
            may be subjected by any person including any governmental authority,
            by reason of breach of any Applicable Law;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - due to failure of a User to obtain any required statutory or
            regulatory approval necessary for the performance of its obligations
            in the applicable agreement with NPay India;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - all liability, claims, damages, costs, expenses suffered or
            incurred by NPay India as a result of any act or violation by User
            under Section 269ST of the Income Tax Act, 1961 while acting as
            collection agent of NPay India;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            NPay India reserves the right, at User ’s expense, to assume the
            exclusive defense and control of any matter, including rights to
            settle, for which concerned User is required to indemnify NPay
            India. The User agrees to cooperate with NPay India’s defense and
            settlement of these claims. NPay India will use reasonable efforts
            to notify the User of any claim, action, or proceeding brought by a
            third party that is subject to the foregoing indemnification upon
            becoming aware of it.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            DISCLAIMER OF WARRANTY
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            To the fullest extent permissible under Applicable Law, NPay India
            and its Affiliates, officers, directors, employees, and agents
            disclaim all warranties, express or implied, including, but not
            limited to, implied warranties of merchantability, fitness for a
            particular purpose, and non-infringement of proprietary rights. No
            advice or information, whether oral or written, obtained by a User
            from NPay India or through NPay India’s Services or the Platform
            will create any warranty not expressly stated herein. NPay India
            does not authorize anyone to make any warranty on its behalf and
            Users should not rely on any such statement. Users acknowledge that
            NPay India is a reseller and is not liable for any third-party
            seller ’s obligations. It is User ’s responsibility to evaluate the
            accuracy, completeness and usefulness of all opinions, advice,
            merchandise, and other information provided through the Platform or
            on the internet generally. NPay India does not warrant that the
            Services will be provided uninterrupted, or free of errors, viruses,
            or other harmful components, and that any of the foregoing defects
            will be corrected. NPay India’s Services and the Platform and any
            data, information, third-party software, Reference Sites, or
            software made available in conjunction with or through its Services
            and the Platform are provided on an “as is” and “as available” and
            “with all faults” basis and without warranties or representations of
            any kind either express or implied. NPay India and its third-party
            suppliers, licensors, and partners do not warrant or make any
            representations regarding the use or the results of the use of the
            Services, the Platform or any Reference Sites in terms of
            correctness, accuracy, reliability, or otherwise.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            INTELLECTUAL PROPERTY
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            NPay India’s Services and the Platform are owned and operated by
            NPay India and/or third-party licensors. The visual interfaces,
            graphics, design, compilation, information, computer code (including
            source code and object code), products, software, services, and all
            other elements of the Services and the Platform provided by N Pay
            India (the “Materials”) are protected by Applicable Law.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            As between User and NPay India, all Materials and NPay India’s
            Intellectual Property are the property of NPay India and/or
            third-party licensors or suppliers as applicable. User agrees not to
            remove, obscure, or alter NPay India or any third-party ’s
            copyright, patent, trademark, or other proprietary rights notices
            affixed to or contained within or accessed in conjunction with or
            through the Services.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Either Party does not accrue any rights or interest in the other
            Party ’s Intellectual Property and use of any Intellectual Property
            by either Party shall be strictly for the fulfilment of and in
            compliance with the terms of the agreement between the Parties.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The Parties undertake that they shall not, nor will they allow
            others to, reverse engineer or disassemble any parts of the other ’s
            Intellectual Property.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            If Users ’have comments regarding the Services and the Platform or
            ideas on how to improve it, please write to our 24x7 Helpdesk at
            help@NPay India.in. Please note that by doing so, concerned User
            hereby irrevocably assign to NPay India, and shall assign to
            NPay India, all right, title and interest in and to all ideas and
            suggestions and any and all worldwide intellectual property rights
            associated therewith. The concerned User agrees to perform such acts
            and execute such documents as may be reasonably necessary to perfect
            the foregoing rights.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            User acknowledges that NPay India has granted the User a personal,
            non-exclusive, non-transferable, limited privilege to avail the
            Services and make personal use of the Platform and/or the Services.
            Therefore, the User undertakes that:
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall use the Intellectual Property solely for discharge of
            their duties under the applicable agreement;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall use Intellectual Property of other party only in the
            form and manner stipulated by such party;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall not sell, license, distribute, copy, modify, publicly
            perform, or display, transmit, publish, edit, adapt, create
            derivative works from, or otherwise make unauthorized use of the
            Materials or NPay India’s Intellectual Property;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall seek prior written consent from other party for use of
            such party ’s Intellectual Property which is not previously provided
            for by such Party;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall bring to other party ’s notices all cases concerning
            such party ’s Intellectual Property ’s (a) infringement, (b) passing
            off, (c) registration, or (d) attempted registration;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall render to other party all assistance in connection with
            any matter pertaining to the protection of such party ’s
            Intellectual Property whether in courts, before administrative
            agencies, or otherwise;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall refrain from taking any action which shall or may
            impair other party ’s right, title, or interest in the Intellectual
            Property, or create any right, title, or interest therein or
            thereto, adverse to that of the other party;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall not use or permit to be used the Intellectual Property
            by any unauthorized person;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall not misuse the Intellectual Property or use it together
            with any other mark(s);
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall not use any of NPay India’s trade names, trademarks,
            service marks, logos, domain names, and other distinctive brand
            features;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall not remove, obscure, or alter any proprietary rights
            notices (including trademark and copyright notices), which may be
            affixed to or contained within the Services; and
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 8 }}>
            - they shall not copy or transmit any of the Services.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            AMENDMENT
          </Typography>
          <Typography
            sx={{
              ml: 4,
              fontWeight: 400,
              fontSize: 20,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            1. Modification of Terms
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            reserves the right to amend, modify, or update these Terms and
            Conditions at any time without prior notice. Users are encouraged to
            review this page periodically to stay informed about any changes.
            Continued use of our services after amendments constitutes
            acceptance of the modified terms.
          </Typography>
          <Typography
            sx={{
              ml: 4,
              fontWeight: 400,
              fontSize: 20,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            2. Notification of Changes
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            In the event of significant changes that may affect user rights or
            alter the way personal information is handled, will make reasonable
            efforts to notify users via email or through prominent announcements
            on our platform. It is the user's responsibility to ensure that
            their contact information is accurate and up-to-date.
          </Typography>
          <Typography
            sx={{
              ml: 4,
              fontWeight: 400,
              fontSize: 20,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            3. User Consent
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            By using 's services, users acknowledge and agree that it is their
            responsibility to regularly review these Terms and Conditions. The
            continued use of our services following modifications indicates
            acceptance of the amended terms. If a user does not agree with the
            updated terms, they should discontinue the use of our services.
          </Typography>
          <Typography
            sx={{
              ml: 4,
              fontWeight: 400,
              fontSize: 20,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            4. Governing Version
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The latest version of the Terms and Conditions will supersede all
            previous versions. The effective date of the current version will be
            clearly stated at the beginning of this document.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            CONFIDENTIALITY
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The User shall keep Confidential Information as confidential. The
            User confirms that it shall protect Confidential Information with
            such security, confidentiality, and degree of utmost care as it
            would prudently apply to its own confidential information and use it
            solely in connection with the transaction to which the Confidential
            Information relates. The User acknowledges and agrees that it is
            aware of the sensitivity &secrecy involved in keeping the customer
            data/information and transaction records and shall ensure that
            neither the User nor their employees, directors etc. will do any act
            to violate the same.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Notwithstanding anything contained in this Clause 1.16 (i),
            Confidential Information shall exclude any information: (a) which is
            already in the possession of the receiving Party and not subject to
            any other duty of confidentiality; (b) that is at the date hereof,
            or subsequently becomes, public otherwise than by reason of a breach
            by the receiving Party of these Terms; (c) information that becomes
            legally available to the receiving Party and/or its Affiliates or
            professional advisors on a non-confidential basis from any
            third-party, the disclosure of which does not, to the knowledge of
            that Party, violate any contractual or legal obligation which such
            third-party has to the other Party with respect to such information;
            and (d) information that is independently acquired or developed by
            the receiving Party and/or its Affiliates or professional advisors.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            The User hereby agrees that it shall not disclose any Confidential
            Information received by it without the prior written consent of
            NPay India to any third-party at any time. Provided however, that
            either Party may make the following disclosures for which no consent
            shall be required from the other Party:
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Disclosures to its directors, officers, employees,
            Affiliates/subsidiaries/group/holding companies, third party service
            providers and any employees thereof that it reasonable determines
            need to receive the Confidential Information;
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Disclosures to its legal and other professional advisers, instructed
            by it that it reasonably determines need to receive the Confidential
            Information; or
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Disclosures to any person to whom it is required under Applicable
            Law or any applicable regulatory, supervisory, judicial, or
            governmental order, to disclose such information, or at the request
            of any regulatory or supervisory or judicial or government
            authority.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            TERMINATION
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            NPay India reserves the right to terminate/suspend these Terms or
            User ’s access to the Platform and Services without prior notice and
            without any liability to the User or any third-party. In the event
            the User wants to terminate the Terms, it can email at help@N Pay
            India.in with a prior notice of thirty (18) days to stop the
            Services. Any suspension/termination of the User ’s access to the
            Platform and Services shall be without prejudice to NPay India’s
            right to exercise any other remedy available to it under the
            Applicable Law.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            GOVERNING LAW AND DISPUTE RESOLUTION
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            These Terms shall be governed by and construed in accordance with
            the laws of India, without regard to its conflict of law provisions
            and the exclusive jurisdiction of competent courts in , India.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            FORCE MAJEURE
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            These Terms shall be governed by and construed in accordance with N
            Pay India shall not be liable for failure to perform its obligations
            under these Terms to the extent such failure is due to causes beyond
            its reasonable control. In the event of a force majeure event, N
            Pay India if unable to perform shall notify the User in writing of
            the force majeure event and the performance obligations of the
            Company will be extended by a period of time equal to the length of
            the delay caused by such force majeure event; provided that if any
            such delay exceeds ninety (90) days, then following such ninety-day
            period, either Party hereto may terminate the unperformed portions
            of these Terms with a ten (10) days prior written notice to the
            other Party. For the purposes of these Terms, force majeure events
            shall include, but not be limited to, acts of God, failures or
            disruptions, orders or restrictions, war or warlike conditions,
            hostilities, sanctions, mobilizations, blockades, embargoes,
            detentions, revolutions, riots, looting, strikes, stoppages of
            labor, lockouts or other labor troubles, earthquakes, fires or
            accidents and epidemics and/or pandemics.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            MISCELLANEOUS
          </Typography>
          <Typography
            sx={{
              ml: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            SEVERABILITY:
            <Typography sx={{ color: "#F4E4E5" }}>
              If any provision of these Terms is held to be unlawful, void,
              invalid or otherwise enforceable.
            </Typography>
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            unenforceable, then such provision shall be limited or eliminated
            from these Terms to the minimum extent required, and the remaining
            provisions shall remain valid and enforceable.
          </Typography>
          <Typography
            sx={{
              ml: 1,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            ASSIGNMENT:
            <Typography sx={{ color: "#F4E4E5" }}>
              These Terms and any rights granted hereunder, shall not be
              assignable by the User, but
            </Typography>
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            may be assigned by NPay India without any restriction. Any
            assignment attempted to be made in violation of this clause 1.19
            shall be void and of no effect.
          </Typography>
          <Typography
            sx={{
              ml: 1,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            HEADINGS:
            <Typography sx={{ color: "#F4E4E5" }}>
              The heading references herein are for convenience purposes only,
              and do not constitute a
            </Typography>
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            part of these Terms, and shall not be deemed to limit or affect any
            of the provisions hereof.
          </Typography>
          <Typography
            sx={{
              ml: 1,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            NOTICE:
            <Typography sx={{ color: "#F4E4E5" }}>
              NPay India may provide Users with notices and communications
              through registered e-mail
            </Typography>
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            regular mail, or posts on the NPay India website(s) or Platform or
            by any other reasonable means. Except as otherwise set forth herein,
            notice to NPay India must be sent by courier or registered post to
          </Typography>
          <Typography
            sx={{
              ml: 1,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            WAIVER:
            <Typography sx={{ color: "#F4E4E5" }}>
              The failure of NPay India to exercise or enforce any right or
              provision of these Terms will not
            </Typography>
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            constitute a waiver of such right or provision. Any waiver of any
            provision of these Terms, express or implied, shall be effective
            only if in writing and signed by NPay India.
          </Typography>
          <Typography
            sx={{
              ml: 1,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            RELATIONSHIP BETWEEN THE PARTIES:
            <Typography sx={{ color: "#F4E4E5" }}>
              The Terms are not intended by the Parties to constitute or
            </Typography>
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 1 }}>
            create a joint venture, pooling arrangement, partnership, agency, or
            formal business organization of any kind. NPay India and the User
            shall be independent contractors with each other for all purposes at
            all times and neither Party shall act as or hold itself out as an
            agent or representative of the other Party nor shall create or
            attempt to create liabilities for the other Party by acting or
            holding itself out as such.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            TERMINATION
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            NPay India reserves the right to terminate or suspend your access
            to and use of the services, in whole or in part, at its sole
            discretion, with or without cause and without prior notice. Reasons
            for termination may include, but are not limited to:
          </Typography>
          <Typography
            sx={{
              ml: 4,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            - Breach of Terms:
            <Typography sx={{ color: "#F4E4E5" }}>
              Violation of any terms and conditions outlined in this agreement.
            </Typography>
          </Typography>
          <Typography
            sx={{
              ml: 4,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            - Illegal or Improper Use:
            <Typography sx={{ color: "#F4E4E5" }}>
              Engaging in activities that are illegal, fraudulent, or improper.
            </Typography>
          </Typography>
          <Typography
            sx={{
              ml: 4,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            - Abuse of Services:
            <Typography sx={{ color: "#F4E4E5" }}>
              Misuse or abuse of 's services or resources.
            </Typography>
          </Typography>
          <Typography
            sx={{
              ml: 4,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            - Security Concerns:
            <Typography sx={{ color: "#F4E4E5" }}>
              If there are security concerns related to your account or
              activities.
            </Typography>
          </Typography>
          <Typography
            sx={{
              ml: 4,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            - Non-Payment:
            <Typography sx={{ color: "#F4E4E5" }}>
              Failure to pay for services rendered as per the Payments and
              Billing Policy.
            </Typography>
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 18,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            Effect of Termination
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            1. Upon termination:
          </Typography>
          <Typography
            sx={{
              ml: 4,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            - Account Access:
            <Typography sx={{ color: "#F4E4E5" }}>
              You will no longer have access to your account and associated
              services.
            </Typography>
          </Typography>
          <Typography
            sx={{
              ml: 4,
              mt: 1,
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            - Data and Content:
            <Typography sx={{ color: "#F4E4E5" }}>
              Any data, content, or information associated with your account may
              be
            </Typography>
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml: 5 }}>
            deleted or retained, as per 's data retention policies.
          </Typography>
          <Typography sx={{ color: "#F4E4E5", p: { xs: 1, md: 1 } }}>
            Termination does not relieve you of any obligations or liabilities
            incurred before termination.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            2. User Termination
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml:1}}>
            You may terminate your account and stop using 's services at any
            time. Termination by the user does not release them from any
            outstanding obligations or liabilities.
          </Typography>
          <Typography
            sx={{
              p: { xs: 1, md: 1 },
              fontWeight: 600,
              fontSize: 15,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            3. Survival of Terms
          </Typography>
          <Typography sx={{ color: "#F4E4E5", ml:1,mb:8}}>
            Sections pertaining to Intellectual Property, Privacy, Payments and
            Billing, Disclaimer of Warranties, Limitation of Liability, and
            Governing Law and Dispute Resolution shall survive the termination
            of this agreement.
          </Typography>
          <Typography>
          <Button
            onClick={navigateToHome}
            variant="contained"
            sx={{ bgcolor: "#E84B4F", m: 1 }}
          >
            Back To Home
          </Button>
        </Typography>
        </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default Terms;
