import React from "react";

import Header from "./components/Header";
import Layout from "./landing-page/layout/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Routers from "../src/pages/Terms and conditions/Routes/Routes";
import Terms from "../src/pages/Terms and conditions/Terms";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrolltoTop from "./components/ScrolltoTop";
import PrivacyPolicy from "../src/pages/Terms and conditions/PrivacyPolicy";


function App() {
  return (
    <>
      <BrowserRouter>
    <ScrolltoTop />
        <Routes>

          <Route  path="/" element={<Home />}/>
            <Route path="/terms" element={<Terms />}/>
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
